import styled from "@emotion/styled";
import tw from "twin.macro";

export const HelpSectionWrapperStyled = styled.div`
  width: fit-content;
  margin: 0 auto;

  ul {
    list-style: none;

    li {
      padding-bottom: 0.7em;
      max-width: 80ch;
    }
  }
`;

export const IconItemStyled = styled.div`
  text-align: center;
  margin-bottom: 1em;

  svg {
    margin: 0 auto;
    max-width: 7em;
  }

  & .subtext {
    margin: 0 auto;
    max-width: 16em;
    font-size: 0.8em;
    line-height: 1.6;
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;

  textarea {
    min-height: 7em;
    padding: 0.25em 0.5em;
    outline: none;
    border: 2px solid rgb(48, 65, 117);
  }

  input {
    border: 2px solid rgb(48, 65, 117);
    margin-bottom: 1em;
    outline: none;
    &[type="checkbox"] {
      margin-bottom: 0;
      margin-right: 0.5em;
    }
  }

  select {
    border: 2px solid rgb(48, 65, 117);
    margin-bottom: 1em;
    outline: none;
  }

  button {
    margin: 0.3em 0;
    padding: 0.3em;
    width: 100%;
    ${tw`text-2xl border-2 bg-three-50 border-two text-four`}
  }
`;

export const MailWrapperStyled = styled.div`
  width: 100%;
  display: inline-block;
  margin: 1em 0;

  svg {
    height: 100%;
    width: 100%;
    max-width: 500px;
  }
`;
