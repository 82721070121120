import React from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const Spin = keyframes`
  0% {
    transform-origin: 0% 0%;
    transform: rotate(0deg) translate(-50%,-50%);
  }
  50% {
    transform-origin: 0% 0%;
    transform: rotate(360deg) translate(-50%,-50%);
  }
  100% {
    transform-origin: 0% 0%;
    transform: rotate(720deg) translate(-50%,-50%);
  }
`;

const BlobsBlobsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  right: -30%;
  top: 0;
  z-index: -2;
  @media (max-width: 1024px) {
    right: 0;
  }
`;

const StyledBlob = styled.img`
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: ${Spin} ${(props) => `${props.time * 50}s`} linear infinite
    ${(props) => props.reverse};
`;
const StyledBlobOverimage = styled(StyledBlob)`
  position: relative;
  width: 70%;
  animation: none;
  filter: drop-shadow(10px 10px 10px rgba(146, 136, 110, 0.25));

  @media (max-width: 1024px) {
    width: 150%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 768px;
  }
`;

const Blobs = () => {
  return (
    <BlobsBlobsContainer>
      <StyledBlob src={"./blob1.svg"} time={4} />
      <StyledBlob src={"./blob2.svg"} time={5} reverse={"reverse"} />
      <StyledBlob src={"./blob4.svg"} time={3} />
      <StyledBlobOverimage src={"./Psychiatric2.svg"} />
    </BlobsBlobsContainer>
  );
};

export default Blobs;
