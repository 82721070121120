import React from "react";
import Blobs from "../blobs";
import { PageWrapper } from "../../layout";
import {
  MessageWithBefore,
  PageSection,
  WizytaButton,
} from "../../../styles/index/_components";
import TextReveal from "../textReveal";
import { textData } from "../../../data";
import { Link } from "gatsby";

const Mainsection = () => {
  return (
    <PageSection tw="mt-12 lg:mt-0" className="min-h-screen overflow-hidden">
      <Blobs />
      <PageWrapper className="justify-center relative">
        <div className="lg:ml-32">
          <MessageWithBefore className="justify-start text-7xl text-bold hidden lg:flex">
            <TextReveal text={"Zadbaj"} delay={400} />
          </MessageWithBefore>
          <h1 className="justify-start text-7xl text-bold hidden lg:flex">
            <TextReveal text={"o siebie!"} delay={200} />
          </h1>
          {/*Mobile*/}
          <MessageWithBefore className="text-4xl text-bold text-center lg:hidden">
            <TextReveal text={"Zadbaj o siebie"} delay={400} />
          </MessageWithBefore>
          {/*Mobile*/}
          <h3 className="flex direction-column text-3xl font-light hidden lg:flex">
            <TextReveal
              text={"O swoje zdrowie psychiczne i seksualne!"}
              delay={600}
            />
          </h3>
        </div>
        <div tw="h-1/2" />
        <Link to={"/kontakt"}>
          <WizytaButton
            style={{
              left: "0",
              textAlign: "center",
              transform: "translateX(0)",
            }}
            className="text-3xl "
          >
            {textData.mainButton}
          </WizytaButton>
        </Link>
      </PageWrapper>
    </PageSection>
  );
};

export default Mainsection;
