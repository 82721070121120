import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { SampleImage } from "../styles/index/_components";
import Mainsection from "../components/index/sections/mainsection";
import Whoarewe from "../components/index/sections/whoarewe";
import Icons from "../components/index/sections/icons";
import Quotesections from "../components/index/sections/quotesections";

const IndexPage = () => {
  return (
    <Layout unwrapped={false}>
      <Mainsection />
      <Whoarewe />
      <SampleImage />
      <Icons />
      <Quotesections />
    </Layout>
  );
};

export default IndexPage;
