import React from "react";
import Quote from "../../blocks/quote";
import { textData } from "../../../data";
import { PageSection } from "../../../styles/index/_components";

const Quotesections = () => {
  return (
    <PageSection>
      <Quote quote={textData.quoteSection} sticky={true} />
    </PageSection>
  );
};

export default Quotesections;
