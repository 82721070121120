import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

const Decs = styled.div`
  display: flex;
  justify-content: center;
`;

const DecsInside = styled.div`
  user-select: none;
  position: relative;
  font-size: 1.5em;
  line-height: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
  ${tw`text-two uppercase`}

  &::before {
    display: block;
    position: absolute;
    content: "";
    ${tw`border-t-2 border-two`}
    width: 3em;
    left: -3.2em;
    top: 0.9em;
  }

  &::after {
    display: block;
    position: absolute;
    content: "";
    ${tw`border-t-2 border-two`}
    width: 3em;
    right: -3.2em;
    top: 0.9em;
  }
`;

const Decorators = ({ text = "-" }) => {
  return (
    <Decs>
      <DecsInside tw="text-xl md:text-3xl">{text}</DecsInside>
    </Decs>
  );
};

export default Decorators;
