import React from "react";
import { PageWrapper } from "../../layout";
import Decorators from "../decorators";
import { textData } from "../../../data";
import { HelpSectionWrapperStyled } from "../../_components";
import { PageSection } from "../../../styles/index/_components";
import "twin.macro";

const Whoarewe = () => {
  return (
    <PageSection tw="bg-two-1 overflow-hidden border-t-2 border-four">
      <PageWrapper className=" text-2xl">
        <Decorators text={textData.explanationSection.decoratorText} />
        <h3 className="text-3xl text-center">
          {textData.explanationSection.title}
        </h3>
        <span className="text-temp-50 text-justify sm:text-center p-4 sm:p-10 font-light">
          <HelpSectionWrapperStyled>
            <ul>
              <li tw="mb-4">{textData.explanationSection.sections[0]}</li>
              <li tw="mb-4">{textData.explanationSection.sections[1]}</li>
              <li>{textData.explanationSection.sections[2]}</li>
            </ul>
          </HelpSectionWrapperStyled>
        </span>
      </PageWrapper>
    </PageSection>
  );
};

export default Whoarewe;
