import React from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const After = keyframes`
  0% {transform: translateX(-100%)}
  100% {transform: translateX(111%)}

`;

const Before = keyframes`
  0% {transform: translateX(0);

    width: 100%;}
  100% {transform: translateX(200%);

    width: 110%;}
`;

const Reveal = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  line-height: 1.2;

  &::before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: white;
    animation: ${Before} 2s cubic-bezier(0.5, 0, 0.15, 1.5) forwards;
    animation-delay: ${(props) => props.delay}ms;
    transform: translateX(0);
  }

  &::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #304175ff;
    animation: ${After} 2s cubic-bezier(0.5, 0, 0.15, 1.5) forwards;
    animation-delay: ${(props) => props.delay}ms;
    transform: translateX(-101%);
  }

  @media (min-width: 1024px) {
    padding-right: 1em;
  }
`;

const TextReveal = ({ text, delay = 0 }) => {
  return <Reveal delay={delay}>{text}</Reveal>;
};

export default TextReveal;
