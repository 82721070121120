import React from "react";
import "twin.macro";
import styled from "@emotion/styled";
import "material-design-icons/iconfont/material-icons.css";
import * as icons from "../imports/imports";
import { IconItemStyled } from "../_components";
import { css } from "@emotion/react";
import tw from "twin.macro";
import { Link } from "gatsby";

export const Material = styled.span`
  font-family: "Material Icons", serif;
  font-weight: normal;
  font-style: normal;
  font-size: 74px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
`;

export const MaterialCentered = styled(Material)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SingleIcon = ({
  text = "- -",
  Icon = "error",
  changeInto = Icon,
  subtext = "",
  link = "#",
}) => {
  return (
    <IconItemStyled>
      <Link to={link}>
        <Icon
          css={css`
            height: 40%;
            width: 100%;
            #one {
              ${tw`fill-current text-two-500`};
            }
          `}
        />
        <div className="text" tw="p-2 text-temp-200 font-medium">
          {text}
        </div>
        <div className="subtext text-temp-100 font-light">{subtext}</div>
      </Link>
    </IconItemStyled>
  );
};

const HelpIconsData1 = [
  {
    text: "Zaburzenia nastroju",
    subtext: "Leczenie depresji i innych problemów związanych z nastrojem",
    changeInto: "sentiment_satisfied",
    icon: icons.not_ok,
  },
  {
    text: "Kryzys psychiczny",
    subtext:
      "Pomoc w przypadku trudnych sytuacji życiowych takich jak: przemoc, utrata zatrudnienia, rozstanie, choroba, trauma, PTSD",
    changeInto: "female",
    icon: icons.psychology,
  },
  {
    text: "Samoocena",
    subtext: "Praca nad niskim poczuciem własnej wartości",
    changeInto: "person_add_alt_1",
    icon: icons.question_triangle,
  },
  {
    text: "Emocje",
    subtext:
      "Praca nad trudnościami z emocjami takimi jak: silne poczucie winy, zachowania autoagresywne lub agresywne, nadmierny stres",
    changeInto: "textsms",
    icon: icons.neurology,
  },
  {
    text: "Psychoedukacja",
    subtext:
      "Edukacja dla osób bliskich i/lub pacjenta w wybranym zakresie dotyczącym zdrowia psychicznego",
    changeInto: "sentiment_satisfied",
    icon: icons.group_discussion_meeting,
  },
];
const HelpIconsData2 = [
  {
    text: "Orientacja seksualna",
    subtext: "Pomoc w akceptacji swojej orientacji seksualnej, edukacja",
    changeInto: "female",
    icon: icons.sexual_reproductive_health,
  },
  {
    text: "Tożsamość płciowa",
    subtext:
      "Pomoc w redukowaniu dysforii płciowej, wsparcie w procesie tranzycji – opiniowanie i edukacja, wsparcie osób o innych tożsamościach płciowych",
    changeInto: "person_add_alt_1",
    icon: icons.transgender,
  },
  {
    text: "Zaburzenia preferencji",
    subtext:
      "Leczenie zaburzeń preferencji seksualnych, pomoc w zrozumieniu fantazji i zachowań seksualnych",
    changeInto: "textsms",
    icon: icons.sti,
  },
  {
    text: "Dysfunkcje seksualne",
    subtext:
      "Leczenie dysfunkcji seksualnych takich jak: zaburzenia odczuwania orgazmu, erekcji i wytrysku, dyspareunii, pochwicy i innych",
    changeInto: "textsms",
    icon: icons.child_cognition,
  },
  {
    text: "Edukacja seksualna",
    subtext:
      "Edukacja dla osób bliskich i/lub pacjenta. Orientacja seksualna, tożsamość płciowa, zaburzenia i dysfunkcje seksualne, HIV, STI",
    changeInto: "textsms",
    icon: icons.family_planning,
  },
];
const HelpIconsData3 = [
  {
    text: "Diagnoza",
    subtext:
      "Diagnostyka pod kątem zaburzeń osobowości, diagnoza inteligencji, wykonanie testów psychologicznych",
    changeInto: "female",
    icon: icons.health_worker_form,
    link: "/oferta/pomoc-psychologiczna",
  },
  {
    text: "Opinia",
    subtext:
      "Sporządzenie opinii w zakresie zdrowia psychicznego lub seksualnego, opinia w procesie tranzycji do procedowania działań afirmujących płeć",
    changeInto: "person_add_alt_1",
    icon: icons.i_documents_accepted,
    link: "/kontakt",
  },
  {
    text: "Zaświadczenia",
    subtext:
      "Zaświadczenie do szkoły, pracy, uczelni itp. dotyczące zdrowia psychicznego lub seksualnego",
    changeInto: "textsms",
    icon: icons.i_note_action,
    link: "/kontakt",
  },
];

const Helpicons = () => {
  return (
    <div>
      <div tw="grid grid-flow-row grid-cols-1 md:grid-cols-5 gap-3">
        {HelpIconsData1.map((icon, i) => {
          return (
            <SingleIcon
                key={i}
              Icon={icon.icon}
              changeInto={icon.changeInto}
              subtext={icon.subtext}
              text={icon.text}
              link={"/oferta/pomoc-psychologiczna/"}
            />
          );
        })}
      </div>
      <div tw="grid grid-flow-row  grid-cols-1 md:grid-cols-5  gap-3">
        {HelpIconsData2.map((icon, i) => {
          return (
            <SingleIcon
                key={i}
              Icon={icon.icon}
              changeInto={icon.changeInto}
              subtext={icon.subtext}
              text={icon.text}
              link={"/oferta/pomoc-seksuologiczna/"}
            />
          );
        })}
      </div>
      <div tw="grid grid-flow-row  grid-cols-1 md:grid-cols-3  gap-3">
        {HelpIconsData3.map((icon, i) => {
          return (
            <SingleIcon
                key={i}
              Icon={icon.icon}
              changeInto={icon.changeInto}
              subtext={icon.subtext}
              text={icon.text}
              link={icon.link}
            />
          );
        })}
      </div>
      <div tw="flex items-center justify-center p-8">
        <Link
          to={"/oferta/szybka-porada-on-line/"}
          tw="transform animate-pulse  p-6 text-xl md:text-3xl border-4 border-four rounded-lg text-four hover:scale-110"
        >
          Szybka porada on-line
        </Link>
      </div>
    </div>
  );
};

export default Helpicons;
