export const textData = {
  mainButton: "Umów się na wizytę",
  jumbotron: {
    main: "",
    secondary: "",
  },
  explanationSection: {
    decoratorText: "Kim jesteśmy?",
    title: "Pomocną dłonią dla Ciebie",
    sections: [
      `Psycholożki/dzy i seksuolożki/dzy specjalizujący się w terapii zaburzeń psychicznych oraz pomocy seksuologicznej. Usługi świadczymy na terenie Trójmiasta. Pracujemy także online. Pozwala nam to nie ograniczać pomocy tylko do obszaru Trójmiasta, lecz rozszerzyć ją na całą Polskę. W razie potrzeby także poza granice kraju. Robimy wszystko, aby zapewnić pacjentom/klientom odpowiednią atmosferę otwartości i bezpieczeństwa, podczas świadczenia naszych usług.`,
      `Jako specjalistkom i specjalistom od zdrowia psychicznego i seksualnego przyświeca nam cel stworzenia miejsca, w którym znajdziecie szacunek dla Waszej różnorodności. Wolność i ekspresja jest dla nas najważniejsza, dlatego też macie tutaj gwarancję szanowania Waszej różnorodności, orientacji seksualnej, tożsamości płciowej czy preferencji seksualnych.`,
      `Różnorodność naszych zainteresowań i doświadczeń pozwala wybrać odpowiedniego specjalistę, który będzie najlepiej dopasowany do Waszych potrzeb. Certyfikujemy się w rekomendowanych przez polskie towarzystwa psychoterapeutyczne szkołach i akademiach. Uczestniczymy w wielu konferencjach o tematyce zdrowia psychicznego i seksualnego. Pozwala nam to zdobywać i aktualizować wiedzę, w celu skuteczniejszego wykonywania naszej pracy.`,
    ],
  },
  iconsSection: {
    decoratorText: "Jak możemy Ci pomóc?",
  },
  quoteSection: {
    quote:
      "Żaden człowiek i żaden ludzki los nie może być porównywany z innym człowiekiem i innym losem. Każda sytuacja jest niepowtarzalna i każda wymaga innych reakcji.",
    quoteAuthor: "Victor Frankl",
  },
};
