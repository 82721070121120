import React from "react";
import { PageWrapper } from "../../layout";
import Decorators from "../decorators";
import { textData } from "../../../data";
import Helpicons from "../helpicons";
import { PageSection } from "../../../styles/index/_components";

const Icons = () => {
  return (
    <PageSection tw="bg-two-1 overflow-hidden border-t-2 border-four">
      <PageWrapper tight={true}>
        <Decorators text={textData.iconsSection.decoratorText} />
        <Helpicons />
      </PageWrapper>
    </PageSection>
  );
};

export default Icons;
